<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-card v-if="reportError" class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1 text-center text-danger">
          {{ reportError.message }}
        </b-card-title>
      </b-card>

      <b-card v-if="reportData" class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1 text-center">
          Проект {{ reportData.project.name }} канал {{ reportData.channel }}
        </b-card-title>
        <b-card-text class="d-block mb-2 text-center">
          Отчет за {{ reportData.createdAt | moment("DD MMMM GGGG") }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2 relative"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Потрачено"
            >
              <b-form-group
                label="Потрачено"
                label-for="spent"
              >
                <b-form-input
                  id="spent"
                  v-model="reportData.spent"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="loadingUpdate"
                  step="0.01"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Охват/Показы"
            >
              <b-form-group
                label="Охват/Показы"
                label-for="coverage"
              >
                <b-form-input
                  id="coverage"
                  v-model="reportData.coverage"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="loadingUpdate"
                  step="0.01"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Клики"
            >
              <b-form-group
                label="Клики"
                label-for="clicks"
              >
                <b-form-input
                  id="clicks"
                  v-model="reportData.clicks"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="loadingUpdate"
                  step="0.01"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Лиды"
            >
              <b-form-group
                label="Лиды"
                label-for="leads"
              >
                <b-form-input
                  id="leads"
                  v-model="reportData.leads"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="loadingUpdate"
                  step="0.01"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Валидных Лидов"
            >
              <b-form-group
                label="Валидных Лидов"
                label-for="validLeads"
              >
                <b-form-input
                  id="validLeads"
                  v-model="reportData.validLeads"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  :disabled="loadingUpdate"
                  step="0.01"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- <validation-provider
              #default="validationContext"
              name="Подписчиков"
              rules="required"
            >
              <b-form-group
                label="Подписчиков"
                label-for="subscribers"
              >
                <b-form-input
                  id="subscribers"
                  v-model="reportData.subscribers"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Комментариев"
              rules="required"
            >
              <b-form-group
                label="Комментариев"
                label-for="comments"
              >
                <b-form-input
                  id="comments"
                  v-model="reportData.comments"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="loadingUpdate"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loadingUpdate"
                    small
                    class="mr-50"
                  />
                  <span>Сохранить</span>
                </div>
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard,
  BLink, BCardTitle,
  // BCardText,
  BFormInvalidFeedback,
  BOverlay, BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import reportsStoreModule from '../reportsStoreModule'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    // BCardText,
    BOverlay,
    BSpinner,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const { router } = useRouter()

    const blankReportData = {
      createdAt: '',
      spent: 0,
      coverage: 0,
      clicks: 0,
      leads: 0,
      validLeads: 0,
      subscribers: 0,
      comments: 0,
    }

    const reportData = ref(null)
    const reportError = ref(null)

    const loading = ref(false)
    const loadingUpdate = ref(false)
    const reportId = ref(null)

    const fetchReport = () => {
      if (router.currentRoute.params.id && router.currentRoute.params.channel) {
        loading.value = true
        store.dispatch('app-reports/fetchLink', {
          id: router.currentRoute.params.id,
          channel: router.currentRoute.params.channel,
        })
          .then(response => {
            const { data } = response
            loading.value = false
            reportId.value = data

            if (data) {
              loading.value = true
              store.dispatch('app-reports/fetchReport', data)
                .then(res => {
                  if (res.data) {
                    reportData.value = res.data
                  }

                  loading.value = false
                })
            }
          })
          .catch(err => {
            reportError.value = err.response.data
            loading.value = false
            reportData.value = null
          })
      } else if (router.currentRoute.params.id) {
        loading.value = true
        store.dispatch('app-reports/fetchReport', router.currentRoute.params.id)
          .then(response => {
            const { data } = response

            if (data) {
              reportData.value = data
            }

            loading.value = false
          })
          .catch(err => {
            reportError.value = err.response.data
            loading.value = false
            reportData.value = null
          })
      }
    }

    fetchReport()

    const resetreportData = () => {
      reportData.value = {
        ...blankReportData,
      }
    }

    const onSubmit = () => {
      loadingUpdate.value = true
      store.dispatch('app-reports/updateReportForm', {
        id: reportId.value || router.currentRoute.params.id,
        data: {
          createdAt: reportData.value.createdAt,
          spent: reportData.value.spent,
          coverage: reportData.value.coverage,
          clicks: reportData.value.clicks,
          leads: reportData.value.leads,
          validLeads: reportData.value.validLeads,
          subscribers: reportData.value.subscribers,
          comments: reportData.value.comments,
        },
      })
        .then(() => {
          loadingUpdate.value = false
        })
        .catch(() => {
          loadingUpdate.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreportData)

    return {
      loading,
      loadingUpdate,
      reportData,
      reportError,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
